export const SIDE_NAV_FONT_SIZE = 12;
export const FONT_SIZE = 14;
export const FILTERS_AND_TAGS_FONT_SIZE = 16;
export const BUTTON_FONT_SIZE = 16;
export const H1_FONT_SIZE = 20;
export const SUPER_TITLE_FONT_SIZE = 24;
export const H2_FONT_SIZE = 18;
export const H3_FONT_SIZE = 16;
export const LINE_HEIGHT_PERCENT = 130;
export const DARK_GREY_CHECKBOXES = '#798B95';
export const BUTTON_HOVER_COLOR_LIGHT = 'rgba(63, 150, 180, 0.15)';
export const BUTTON_HOVER_COLOR_LIGHTER = 'rgba(63, 150, 180, 0.1)';
export const LIST_ITEM_HOVER_COLOR = 'rgba(194, 201, 209, 0.3)';
export const TEXT_AREA_BACKGROUND_COLOR = 'rgba(194,201,209, 0.2)'; //'#C2C9D1'
export const CARD_OUTLINES_COLOR = 'rgba(194,201,209, 0.5)'; //'#C2C9D1'
export const SEARCH_BACKGROUND_COLOR = '#F9FAFB';
export const MAIN_LAYOUT_BACKGROUND_COLOR = 'F4F6FC';
export const DARK_GREY_LABEL = '#798B95';
export const HELPER_DEFAULT_COLOR = '#5E6368';
export const DEFAULT_FONT_FAMILY = 'Source Sans Pro';
export const BOX_SHADOW_LEFT = '-4px 4px 12px 0px #798B951F';
export const ORANGE_BACKGROUND = 'rgba(238, 87, 87, .15)';

// colors
export const PRIMARY_COLOR = '#31748C';
export const SECONDARY_COLOR = '#1F4958';
export const PRIMARY_COLOR_LIGHT = 'rgba(49, 116, 140, 0.15)';
export const DISABLED_LINES_OUTLINES_COLOR = '#f9fafb';
export const DARKEST_GRAY_COLOR = '#4A5B65';
export const ORANGE_COLOR = '#EE5757';
export const WARNINGS_COLOR = '#F43C5D';
export const LINES_OUTLINES_DISABLED_COLOR = '#C2C9D1';
export const ATTENTION_COLOR = '#DC4200';
export const ERROR_COLOR = '#E30D34';
export const WHITE_COLOR = '#FFFFFF';
export const FIELD_REQUIRED_COLOR = '#E30D34';
export const TOOLTIP_BACKGROUND_COLOR = '#4E5865';
export const DASHBOARD_CARD_TITLE_COLOR = '#002C3E';
export const DASHBOARD_CARD_BACKGROUND_COLOR = '#F5F6F7';
export const CARD_DOTTED_LINE_COLOR = '#005A7A';
export const DASHBOARD_CARD_TEXT_COLOR = '#002C3E';
export const DASHBOARD_CARD_SEE_MORE_COLOR = 'rgba(220, 66, 0, 1.0)';
export const DASHBOARD_CARD_SEE_MORE_ON_HOVER_COLOR = 'rgba(220, 66, 0, 0.1)';
export const HOVER_PRIMARY_COLOR = '#3F96B4';
export const TOOLTIP_SECONDARY_BACKGROUND_COLOR = '#002C3E';
export const DASHBOARD_CARD_DOTTED_LINE_COLOR = 'rgba(0, 90, 122, 0.40)';
export const DARK_BLUE_TEXT = '002C3E';
export const LIGHT_GREEN_COLOR = '#DAFAE7';
export const DARK_GREEN_COLOR = '#075D2A';
export const LIGHT_BLUE_COLOR = '#E0F7FF';
export const DARK_BLUE_COLOR = '#185569';

// PDFTron colors
export const ANNOTATION_OUTLINE_COLOR = [31, 73, 88];
export const TRANSPARENT_COLOR = [0, 0, 0, 0];

// Search bar icons standard
export const SEARCH_ICON_DIAMETER = 16;
export const CLOSE_ICON_DIAMETER = 24;

export const textStyles = {
  boldSuperTitleSecondaryColorText: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${SUPER_TITLE_FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT_PERCENT}%`,
    fontWeight: '600',
    color: SECONDARY_COLOR,
  },
  superTitleText: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${SUPER_TITLE_FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT_PERCENT}%`,
    fontWeight: '400',
  },
  smallCheckboxesColorText: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${SIDE_NAV_FONT_SIZE}px`,
    lineHeight: '16px',
    fontWeight: '600',
    color: DARK_GREY_CHECKBOXES,
  },
  smallSecondaryColorText: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${SIDE_NAV_FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT_PERCENT}%`,
    fontWeight: '400',
    color: SECONDARY_COLOR,
  },
  bodySecondaryColorText: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT_PERCENT}%`,
    fontWeight: '400',
    color: SECONDARY_COLOR,
  },
  bodyPrimaryColorText: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT_PERCENT}%`,
    fontWeight: '400',
    color: PRIMARY_COLOR,
  },
  boldBodySecondaryColorText: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT_PERCENT}%`,
    fontWeight: '600',
    color: SECONDARY_COLOR,
  },
  boldBodyAttentionColorText: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT_PERCENT}%`,
    fontWeight: '600',
    color: ATTENTION_COLOR,
  },
  largeSecondaryColorText: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${FILTERS_AND_TAGS_FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT_PERCENT}%`,
    fontWeight: '400',
    color: SECONDARY_COLOR,
  },
  boldLargeSecondaryColorText: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${FILTERS_AND_TAGS_FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT_PERCENT}%`,
    fontWeight: '600',
    color: SECONDARY_COLOR,
  },
  boldLargeAttentionColorText: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${BUTTON_FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT_PERCENT}%`,
    fontWeight: '600',
    color: ATTENTION_COLOR,
  },
  boldLargeWarningColorText: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${BUTTON_FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT_PERCENT}%`,
    fontWeight: '600',
    color: WARNINGS_COLOR,
  },
  primaryColorTitle: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${H1_FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT_PERCENT}%`,
    fontWeight: '400',
    color: PRIMARY_COLOR,
  },
  boldPrimaryColorTitle: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${H1_FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT_PERCENT}%`,
    fontWeight: '600',
    color: PRIMARY_COLOR,
  },
  boldDarkBlueTitle: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${H1_FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT_PERCENT}%`,
    fontWeight: '600',
    color: DARK_BLUE_TEXT,
  },
  boldDarkBlueSmall: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${SIDE_NAV_FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT_PERCENT}%`,
    fontWeight: '600',
    color: DARK_BLUE_TEXT,
  },
  regularDarkBlueMedium: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT_PERCENT}%`,
    fontWeight: '400',
    color: DARK_BLUE_TEXT,
  },
  boldOrangeSmall: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${SIDE_NAV_FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT_PERCENT}%`,
    fontWeight: '600',
    color: ORANGE_COLOR,
  },
  boldSecondaryColorTitle: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${H1_FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT_PERCENT}%`,
    fontWeight: '600',
    color: SECONDARY_COLOR,
  },
  smallerBoldPrimaryColorTitle: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${H2_FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT_PERCENT}%`,
    fontWeight: '600',
    color: PRIMARY_COLOR,
  },
  smallerBoldSecondaryColorTitle: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${H2_FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT_PERCENT}%`,
    fontWeight: '600',
    color: SECONDARY_COLOR,
  },
  tagsFiltersUnderDocTitle: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${H3_FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT_PERCENT}%`,
    fontWeight: '400',
    color: SECONDARY_COLOR,
  },
  secondaryColorTitle: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${H1_FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT_PERCENT}%`,
    fontWeight: '400',
    color: SECONDARY_COLOR,
  },
  sideNavBold: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: SECONDARY_COLOR,
  },
  popupTitle: {
    fontFamily: `${DEFAULT_FONT_FAMILY} !important`,
    fontSize: '20px !important',
    lineHeight: `${LINE_HEIGHT_PERCENT}% !important`,
    fontWeight: '600 !important',
    color: `${SECONDARY_COLOR} !important`,
  },
  popupBody: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: '16px',
    fontWeight: '400',
    color: SECONDARY_COLOR,
  },
  gridOverlay: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: '16px',
    fontWeight: '400',
    color: SECONDARY_COLOR,
  },
  outlines: {
    color: LINES_OUTLINES_DISABLED_COLOR,
  },
  buttonStyles: {
    common: {
      color: PRIMARY_COLOR,
      fontWeight: '400',
      fontSize: `${BUTTON_FONT_SIZE}px`,
      lineHeight: `${LINE_HEIGHT_PERCENT}%`,
      textAlign: 'center',
      letterSpacing: '0.5px',
    },
    error: {
      color: WARNINGS_COLOR,
      hover: {
        background: 'rgba(244, 60, 93, 0.12)',
        backgroundFullOpacity: '#F7738B',
      },
      disabled: {
        color: DISABLED_LINES_OUTLINES_COLOR,
      },
    },
    secondary: {
      hover: {
        background: 'rgba(49, 116, 140, 0.12)',
      },
      disabled: {
        color: '#C2C9D1',
      },
    },
    primary: {
      background: `${PRIMARY_COLOR} !important`,
      color: `${WHITE_COLOR} !important`,
      hover: {
        background: '#3F96B4 !important',
      },
      disabled: {
        background: '#C2C9D1 !important',
      },
    },
    text: {
      color: `${SECONDARY_COLOR} !important`,
      hover: {
        background: BUTTON_HOVER_COLOR_LIGHT,
      },
    },
  },
  dropdownSelectedValue: {
    color: BUTTON_HOVER_COLOR_LIGHT,
  },
  checkboxStyles: {
    color: '#798B95',
    checked: {
      color: SECONDARY_COLOR,
    },
  },
  radioStyles: {
    color: '#798B95',
    checked: {
      color: SECONDARY_COLOR,
    },
  },
  focusVisibleStyles: {
    outlineOffset: '1px',
    outline: `2px dashed ${PRIMARY_COLOR}`,
  },
  focusVisibleStylesHighOffset: {
    outlineOffset: '2px',
    outline: `2px dashed ${PRIMARY_COLOR}`,
  },
  focusVisibleStylesInset: {
    outlineOffset: '-2px',
    outline: `2px dashed ${PRIMARY_COLOR}`,
  },
  focusVisibleStylesSolid: {
    outlineOffset: '1px',
    outline: `1px solid ${PRIMARY_COLOR}`,
  },
  focusVisibleStylesFileTitles: {
    outlineOffset: '1px',
    outline: `1px solid ${SECONDARY_COLOR}`,
  },
  headerOne: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontWeight: '600',
    fontSize: `${H1_FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT_PERCENT}%`,
    color: SECONDARY_COLOR,
  },
  accordionSummary: {
    background: '#E2EAED',
    height: '32px',
    borderRadius: '12px',

    '&.Mui-expanded': {
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
    },
  },
  attentionChip: {
    color: WHITE_COLOR,
    fontWeight: '600',
    fontSize: `${SIDE_NAV_FONT_SIZE}px`,
    lineHeight: `${LINE_HEIGHT_PERCENT}%`,
    background: ATTENTION_COLOR,
  },
  helperDefaultFont: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${SIDE_NAV_FONT_SIZE}px`,
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0em',
    textAlign: 'right',
    color: HELPER_DEFAULT_COLOR,
  },
  sidebarFieldValue: {
    fontFamily: 'Source Sans Pro !important',
    fontSize: '14px !important',
    lineHeight: '130% !important',
    fontWeight: '400 !important',
    color: `${DARKEST_GRAY_COLOR} !important`,
    overflowWrap: 'break-word',
    hyphens: 'auto',
  },
  knowledgeCardDateText: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${FONT_SIZE}px`,
    fontWeight: '400',
    color: SECONDARY_COLOR,
  },
  knowledgeCardQuestionText: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${H3_FONT_SIZE}px`,
    fontWeight: '600',
    color: SECONDARY_COLOR,
  },
  knowledgeCardAnswerText: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${FONT_SIZE}px`,
    fontWeight: '400',
    color: SECONDARY_COLOR,
  },
  knowledgeCardVerifyText: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${FONT_SIZE}px`,
    fontWeight: '600',
    color: WHITE_COLOR,
  },
  loadMoreText: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${H3_FONT_SIZE}px`,
    fontWeight: '400',
    color: SECONDARY_COLOR,
  },
  loadMoreButtonProgressText: {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: `${FONT_SIZE}px`,
    fontWeight: '400',
    color: SECONDARY_COLOR,
  },
};
