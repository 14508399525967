export const COLLECTION_SHEET_FETCH_LIMIT = 100;
export const COLLECTION_NAME_MAX_LENGTH = 70;
export const COLLECTION_DESCRIPTION_MAX_LENGTH = 5000;
export const COLLECTION_NOTE_MAX_LENGTH = 5000;

export const COLLECTIONS_ICON_DIAMETER = '72px';
export const COLLECTIONS_ICON_CONTAINER_DIAMETER = '157px';

export const COLLECTIONS_CONFIRM_UPDATE_DO_NOT_ASK_AGAIN = 'collectionConfirmUpdate.doNotAskAgain';

export const COLLECTION_SHEET_SORT_BY_ENUM = {
  alphabetical: {
    dataKey: 'title',
    titleKey: 'components.sortByMenu.alphabetical',
    columnName: 'components.listView.columns.title',
  },
  date: {
    dataKey: 'updatedDate',
    titleKey: 'components.sortByMenu.date',
    columnName: 'components.listView.columns.updatedDate',
  },
};

export const COLLECTION_RESULT_SORT_BY_ENUM = {
  author: 'author',
  content: 'content',
  lineOfBusiness: 'line_of_business',
  title: 'title',
  region: 'region',
};

export const COLLECTION_RESULT_SORT_DIRECTION_ENUM = {
  ASC: 'ascending',
  DESC: 'descending',
};

export const sheetLastModified = (sheet) => {
  return new Date(sheet.updatedAt).getTime() !== new Date(0).getTime() ? sheet.updatedAt : sheet.createdAt;
};

export const COLLECTION_SHEET_OPENING_SOURCE = {
  COLLECTIONS: 'collections',
  DOCUMENT_VIEWER: 'documentViewer',
  LINK: 'link',
};
